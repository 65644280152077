<template>
  <div class="flex items-center">
    <div class="rounded-full w-[8px] h-[8px]" :class="getColorClass"></div>
  </div>
</template>

<script>
export default {
  name: 'StatusDot',

  props: {
    intent: {
      type: String,
      required: true,
      validator: (val) => {
        return ['success', 'warning', 'danger'].includes(val)
      },
    },
  },

  computed: {
    getColorClass() {
      if (this.intent === 'success') {
        return 'bg-lime-500'
      }

      if (this.intent === 'warning') {
        return 'bg-orange-500'
      }

      return 'bg-rose-500'
    },
  },
}
</script>