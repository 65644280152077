<template>
  <div>
    <Dialog :isOpen="open" :title="title" @close="onDialogClose">
      <template #description>
        <slot name="description" v-if="slots.description"></slot>
      </template>

      <RedfordInput
        :label="label"
        v-model="internalValue"
        class="mt-4"
        @input="
          (evt) => {
            $emit('update:modelValue', internalValue)
          }
        "
      />

      <template #footer>
        <DefaultButton
          intent="success"
          @click="() => emits('dialogConfirm:confirm')"
          rounded
          :disabled="disabled"
          >Confirm</DefaultButton
        >
        <DefaultButton
          intent="danger"
          class="ml-2"
          @click="() => emits('dialogConfirm:close')"
          rounded
          >Cancel</DefaultButton
        >
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import Dialog from '@/components/Dialog.vue'
import DefaultButton from '@/components/DefaultButton.vue'
import RedfordInput from '@/components/Forms/RedfordInput.vue'
import { defineProps, ref, useSlots } from 'vue'

const emits = defineEmits([
  'dialogConfirm:close',
  'update:modelValue',
  'dialogConfirm:confirm',
])

const slots = useSlots()

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },

  title: {
    type: String,
    required: true,
  },

  modelValue: {
    type: String,
    required: false,
    default: '',
  },

  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },

  label: {
    type: String,
    required: true,
  },
})

const interalValue = ref(props.modelValue)

const onDialogClose = () => {
  emits('dialogConfirm:close')
}
</script>
