<template>
  <div class="page page--home">
    <div class="page__container">
      <div class="logo">
        <img class="logo__image" src="@/assets/robert.png" />
      </div>
      <div class="logo-type">
        <span class="logo-type__first">ROBOT</span
        ><span class="logo-type__last">REDFORD</span>
      </div>
      <Button type="button" @click="onLoginClick" class="login-btn" glow
        >Log in with twitch
      </Button>
    </div>
  </div>
</template>

<script>
import { generateUniqueKey } from '@/util'
import Button from '@/components/Button.vue'

export default {
  name: 'PageHome',

  components: {
    Button,
  },

  computed: {
    getAuthLink() {
      const clientId = process.env.VUE_APP_TWITCH_CLIENT_ID
      const redirectUrl = process.env.VUE_APP_TWITCH_REDIRECT_URL
      const scopes = [
        'user:read:email',
        'channel:read:redemptions',
        'channel:read:subscriptions',
        'moderator:manage:shoutouts',
      ]
      const stateToken = generateUniqueKey()
      const url = `https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&scope=${scopes
        .map((scope) => encodeURIComponent(scope))
        .join('+')}&state=${stateToken}`
      return url
    },
  },

  methods: {
    onLoginClick() {
      window.location.href = this.getAuthLink
    },
  },
}
</script>

<style lang="scss" scoped>
.page--home {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .page__container {
    display: block;
    text-align: center;
  }
}
.logo {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  .logo__image {
    display: block;
    width: 50%;
  }
}

.logo-type {
  color: #fff;
  font-size: 50px;

  .logo-type__first {
    font-weight: 900;
  }
  .logo-type__last {
    font-weight: 300;
  }
}

.login-btn {
  margin-top: 32px;
}
</style>
