const clientId = process.env.VUE_APP_TWITCH_CLIENT_ID

const appendQuery = (url, query = {}) => {
  if (Object.keys(query).length === 0) {
    return url
  }
  return (url += Object.entries(query).reduce((acc, [key, value]) => {
    acc += acc === '?' ? `${key}=${value}` : `&${key}=${value}`
    return acc
  }, '?'))
}

export default class Service {
  constructor(url, endpoint = '', token = '') {
    this.url = url
    this.endpoint = endpoint
    this.token = token
  }

  post(param = '', data = {}) {
    const url = param
      ? `${this.url}/${this.endpoint}/${param}`
      : `${this.url}/${this.endpoint}`
    return fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'include', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Client-Id': clientId,
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    }).then((response) => response.json())
  }

  get(param = '', query = {}, cookie) {
    const headers = {
      'Content-Type': 'application/json',
    }
    if (cookie) {
      headers.cookie = cookie
    }

    let url = param
      ? `${this.url}/${this.endpoint}/${param}`
      : `${this.url}/${this.endpoint}`
    url = appendQuery(url, query)
    return fetch(url, {
      method: 'GET',
      cache: 'no-cache',
      mode: 'cors',
      credentials: 'include',
      headers,
    })
      .then((response) => {
        if (response.status === 401) {
          return {
            status: response.status,
          }
        }
        return response.json()
      })
      .then((result) => {
        if (result.status && result.status === 401) {
          throw new Error('Unauthorized')
        }

        if (result.meta) {
          return result
        }

        if (result.data) {
          return result.data
        }

        return result
      })
  }

  put(param = '', data) {
    return fetch(`${this.url}/${this.endpoint}/${param}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        return json
      })
  }

  delete(param = '') {
    return fetch(`${this.url}/${this.endpoint}/${param}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        return json
      })
  }

  upload(param = '', formData) {
    const url = param
      ? `${this.url}/${this.endpoint}/${param}`
      : `${this.url}/${this.endpoint}`
    return fetch(url, {
      method: 'POST',
      credentials: 'include',
      body: formData,
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        return json
        // this.sounds.push(sound)
      })
  }
}
