<template>
  <button
    type="button"
    class="inline-flex justify-center items-center bg-black px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 transition-colors duration-250"
    :class="classes"
  >
    <LoadingIndicatorSimple
      v-if="isLoading"
      :intent="intent"
      class="h-4 w-4 mr-2"
    />

    <slot />
  </button>
</template>

<script setup>
// class="px-4 py-2 duration-500 inline-flex justify-center border border-transparent text-sm font-medium focus:outline-none transition-colors"

import { computed } from '@vue/reactivity'
import LoadingIndicatorSimple from './LoadingIndicatorSimple.vue'
const props = defineProps({
  intent: {
    type: String,
    required: false,
    default: 'default',
    validator: (val) =>
      ['default', 'success', 'warning', 'danger'].includes(val),
  },

  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },

  rounded: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const classes = computed(() => {
  const styles = []
  if (props.rounded) {
    styles.push('rounded-md')
  }

  if (props.intent === 'success') {
    styles.push(
      'bg-lime-200 hover:bg-lime-400 text-lime-900 hover:text-slate-900'
    )
  } else if (props.intent === 'danger') {
    styles.push(`bg-red-200 hover:bg-red-400 text-red-600 hover:text-white`)
  } else if (props.intent === 'warning') {
    styles.push(`bg-orange-200 hover:bg-orange-400 text-orange-900`)
  } else {
    styles.push('bg-opacity-20 hover:bg-opacity-60')
  }

  return styles.join(' ')
})
</script>
