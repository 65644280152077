<template>
  <div>
    <Headline>Settings</Headline>

    <div>
      <ToggleButton :value="true" />

      <div class="flex justify-center">
        <div class="form-check form-switch">
          <input
            class="
              form-check-input
              appearance-none
              w-9
              -ml-10
              rounded-full
              float-left
              h-5
              align-top
              bg-white bg-no-repeat bg-contain bg-gray-300
              focus:outline-none
              cursor-pointer
              shadow-sm
            "
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
          />
          <label
            class="form-check-label inline-block text-gray-800"
            for="flexSwitchCheckDefault"
            >Default switch checkbox input</label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Headline from '@/components/Headline.vue'
import ToggleButton from '@/components/ToggleButton.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PageSettings',
  computed: {
    ...mapGetters({
      user: 'user/user',
    }),
  },
  methods: {
    ...mapActions({
      validateUser: 'user/validate',
      setUser: 'user/set',
      getUser: 'user/me',
      setSettings: 'user/setSettings',
    }),
  },
  components: { Headline, ToggleButton },
}
</script>