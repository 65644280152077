import Service from '@/util/Service'

const ApiUrl = `${process.env.VUE_APP_URL_API}/v${process.env.VUE_APP_API_VERSION}`
const UserService = new Service(ApiUrl, 'user')
const statisticService = new Service(ApiUrl, 'statistic')

const getMostActiveUserByEvent = async (event) => {
  return await statisticService.get(`most-active/${event}`)
}

// initial state
const state = () => ({
  mostActiveUserByEvent: {
    sound: [],
    gif: [],
  },
})

// getters
const getters = {
  mostActiveUserByEvent: (state) => state.mostActiveUserByEvent,
}

// actions
const actions = {
  async me({ commit }, { cookie = null } = {}) {
    try {
      const { user, settings } = await UserService.get('me', {}, cookie)
      commit('user', user)
      commit('settings', settings)
      return true
    } catch (err) {
      return false
    }
  },

  async getMostActiveUserByEvent({ commit }, { cookie = null, event } = {}) {
    try {
      const result = await statisticService.get(`most-active/${event}`)
      commit('mostActiveUserByEvent', { event, result })
    } catch (err) {
      console.log(err)
    }
  },
}

// mutations
const mutations = {
  mostActiveUserByEvent(state, { event, result }) {
    state.mostActiveUserByEvent[event] = result
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
