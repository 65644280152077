<template>
  <div>
    <Headline>System</Headline>
    <div class="mt-8">
      <Table :isLoading="isLoading">
        <thead>
          <tr>
            <TableHeadCell
              v-for="(tableHead, index) in ['Type', 'Created At', 'Status']"
              :key="index"
            >
              {{ tableHead }}
            </TableHeadCell>
          </tr>
        </thead>

        <tbody class="bg-white dark:bg-slate-800">
          <tr v-for="subscription in subscriptions" :key="subscription.id">
            <TableBodyCell
              v-for="(key, index) in ['type', 'createdAt', 'status']"
              :key="index"
            >
              <span v-if="key === 'status'">
                <StatusDot :intent="subscription[key]" />
              </span>
              <span v-else-if="key === 'createdAt'">
                {{ getFormatedDate(subscription[key]) }}
              </span>
              <span v-else>
                {{ subscription[key] }}
              </span>
            </TableBodyCell>
          </tr>
        </tbody>
      </Table>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import Headline from '@/components/Headline.vue'
import StatusDot from '@/components/StatusDot.vue'
import Table from '@/components/table/Table.vue'
import TableHeadCell from '@/components/table/TableHeadCell.vue'
import TableBodyCell from '@/components/table/TableBodyCell.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PageSystem',

  components: {
    Headline,
    StatusDot,
    Table,
    TableHeadCell,
    TableBodyCell,
  },

  data() {
    return {
      isLoading: true,
    }
  },

  computed: {
    ...mapGetters({
      user: 'user/user',
      subscriptions: 'system/subscriptions',
    }),
  },

  async mounted() {
    await this.$store.dispatch('system/subscriptions', {
      channel: this.user.channelId,
    })
    this.isLoading = false
  },

  methods: {
    getFormatedDate(val) {
      return dayjs(val).format('DD/MM/YYYY HH:mm:ss')
    },
  },
}
</script>
