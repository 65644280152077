<template>
  <aside
    class="sidebar bg-stone-900[.25] fixed h-full w-72 left-0 top-0 py-8 px-0"
  >
    <navigation-main />
  </aside>
</template>

<script>
import NavigationMain from './NavigationMain.vue'
export default {
  components: { NavigationMain },
  name: 'Sidebar',
}
</script>

<style lang="scss" scoped>
.page__sidebar {
  // background-color: rgba(0, 0, 0, 0.25);
}
</style>