<template>
  <div>
    <navigation-item
      v-for="({ to, title }, index) in getMainNavigationItems"
      :key="`navItem-${index}`"
      :to="to"
      >{{ title }}</navigation-item
    >
  </div>
</template>

<script>
import NavigationItem from './NavigationItem.vue'
export default {
  name: 'NavigationMain',

  components: {
    NavigationItem,
  },

  computed: {
    getMainNavigationItems() {
      return this.$router
        .getRoutes()
        .filter((route) => route.meta?.mainNavigation)
        .map((route) => ({
          title: route?.meta?.navigationName,
          to: route?.path,
        }))
    },
  },
}
</script>

<style lang="scss" scoped>
</style>