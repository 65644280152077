import PageHome from '../pages/PageHome.vue'
import PageLogin from '../pages/PageLogin.vue'
import PageDashboard from '../pages/PageDashboard.vue'
import PageCommand from '../pages/PageCommand.vue'
import PageLogout from '../pages/PageLogout.vue'
import PageSettings from '../pages/PageSettings.vue'
import PageSystem from '../pages/PageSystem.vue'
// import PageEvents from '../pages/PageEvents.vue'

export const routes = [
  {
    name: 'home',
    path: '/',
    component: PageHome,
    meta: { public: true },
  },
  {
    name: 'login',
    path: '/login',
    component: PageLogin,
    meta: { public: true },
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    component: PageDashboard,
    meta: { mainNavigation: true, navigationName: 'Dashboard' },
  },
  // {
  //   name: 'events',
  //   path: '/events',
  //   component: PageEvents,
  //   meta: { mainNavigation: true, navigationName: 'Events' },
  // },
  {
    name: 'commands',
    path: '/commands',
    component: PageCommand,
    meta: { mainNavigation: true, navigationName: 'Commands' },
  },
  {
    name: 'settings',
    path: '/settings',
    component: PageSettings,
    meta: { mainNavigation: true, navigationName: 'Settings' },
  },
  {
    name: 'system',
    path: '/system',
    component: PageSystem,
    meta: { mainNavigation: true, navigationName: 'System' },
  },
  {
    name: 'logout',
    path: '/logout',
    component: PageLogout,
    meta: { mainNavigation: true, navigationName: 'Log out', public: true },
  },
]
