import { createStore, createLogger } from 'vuex'
import createCache from 'vuex-cache'
import user from './modules/user'
import statistic from './modules/statistic'
import system from './modules/system'
import notification from './modules/notification'
import command from './modules/command'

const debug = process.env.NODE_ENV !== 'production'

const plugins = [createCache({ timeout: 10000 })]
if (debug) {
  plugins.push(createLogger())
}

export default createStore({
  modules: {
    user,
    statistic,
    system,
    notification,
    command,
  },
  strict: debug,
  plugins,
})
