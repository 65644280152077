<template>
  <button :class="getClasses"><slot /></button>
</template>

<script>
export default {
  name: 'RR-Button',

  props: {
    glow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    getClasses() {
      const classes = ['btn']
      if (this.glow) {
        classes.push('btn--glow')
      }
      return classes.join(' ')
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  border: none;
  outline: none;
  background-color: #d0ff00;
  color: #000;
  border-radius: 16px;
  padding: 8px 16px;
  transition: box-shadow 0.15s ease-in-out;
  box-shadow: 0 0 0 0 #d0ff00;

  &:hover {
    box-shadow: 0px 0px 0px 2px darken(#d0ff00, 20%);
    cursor: pointer;
  }

  &.btn--glow {
    transition: box-shadow 0.25s ease-in-out;
    border: none;
    box-shadow: 0 0 0 0 #d0ff00;
    &:hover {
      border: none;
      box-shadow: 0 0 7px 0 #d0ff00;
    }
  }
}
</style>