<template>
  <div class="chart" ref="chart"></div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'DonutChart',

  props: {
    data: {
      type: Array,
      required: true,
    },

    name: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      chart: null,
    }
  },

  mounted() {
    this.chart = echarts.init(this.$refs.chart, 'dark')
    const option = {
      title: {
        text: this.name,
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
      },
      legend: {
        top: '7.5%',
        left: 'center',
      },
      backgroundColor: 'transparent',
      series: [
        {
          top: '10%',
          name: this.name,
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: false,
              fontSize: '40',
              fontWeight: 'bold',
            },
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          labelLine: {
            show: false,
          },

          data: this.data,
        },
      ],
    }

    option && this.chart.setOption(option)
  },
}
</script>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 100%;
}
</style>