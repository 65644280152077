import Service from '@/util/Service'

const ApiUrl = `${process.env.VUE_APP_URL_API}/v${process.env.VUE_APP_API_VERSION}`

const UserService = new Service(ApiUrl, 'user')
const QueueService = new Service(ApiUrl, 'queue')
// const SoundQueueService = new Service(ApiUrl, 'queue')
const SoundService = new Service(ApiUrl, 'sound')

// initial state
const state = () => ({
  user: {},
  settings: {},
  queue: [],
  sounds: [],
  soundQueue: [],
  gifQueue: [],
  isLoading: true,
})

// getters
const getters = {
  settings: (state) => state.settings,
  user: (state) => state.user,
  hasUser: (state) => Object.keys(state.user).length > 0,
  queue: (state) => state.queue,
  sounds: (state) => state.sounds,
  soundQueue: (state) => state.soundQueue,
  gifQueue: (state) => state.gifQueue,
  isLoading: (state) => state.isLoading,
}

// actions
const actions = {
  async me({ commit }, { cookie = null } = {}) {
    try {
      const { user, settings } = await UserService.get('me', {})
      commit('user', user)
      commit('settings', settings)
      return true
    } catch (err) {
      console.log(err)
      return false
    }
  },

  validate(_, twitchToken) {
    try {
      return UserService.get('validate', {
        access_token: twitchToken,
      })
    } catch (err) {
      console.log(err)
    }
  },

  set({ commit }, user) {
    commit('user', user)
  },

  setSettings({ commit }, settings) {
    commit('settings', settings)
  },

  async logout({ commit }) {
    await UserService.post('logout')
    commit('user', {})
    commit('settings', {})
  },

  updateSetting({ commit, state }, { setting, value }) {
    commit('settings', { ...state.settings, [setting]: value })
  },

  saveSettings({ state }) {
    UserService.post('settings', state.settings)
  },

  async getQueue({ commit }, { cookie, entity }) {
    try {
      const queue = await QueueService.get(entity, {}, cookie)
      commit(`${entity}Queue`, queue)
    } catch (err) {
      throw new Error(err)
    }
  },

  async getSoundQueue({ commit }, { cookie }) {
    try {
      const queue = await SoundService.get('queue', {}, cookie)
      commit('soundQueue', queue)
    } catch (err) {
      throw new Error(err)
    }
  },

  async getSounds({ commit }, { cookie }) {
    try {
      const sounds = await SoundService.get('', {}, cookie)
      commit('sounds', sounds)
    } catch (err) {
      throw new Error(err)
    }
  },

  async updateSound({ commit, state }, { index, newVal }) {
    commit('updateSound', { index, newCommand: newVal })
    await SoundService.put(state.sounds[index].id, { command: newVal })
  },

  async deleteSound({ commit, state }, index) {
    await SoundService.delete(state.sounds[index].id)
    commit('deleteSound', index)
  },

  async addSound({ commit }, sound) {
    try {
      const upload = await SoundService.upload('upload', sound)
      commit('addSound', upload)
    } catch (err) {
      console.log(err)
    }
  },

  async clearQueue({ commit }, queueName) {
    try {
      await QueueService.delete(queueName)
      commit(`${queueName}Queue`, [])
    } catch (err) {
      console.log(err)
    }
  },

  async getGifQueue({ commit }, { cookie }) {
    try {
      const queue = await QueueService.get('gif', {}, cookie)
      commit('gifQueue', queue)
    } catch (err) {
      throw new Error(err)
    }
  },

  async exchangeCode(_, params) {
    try {
      await UserService.post('token', params)
    } catch (err) {
      throw new Error(err)
    }
  },
}

// mutations
const mutations = {
  settings(state, settings) {
    state.settings = settings
  },

  user(state, user) {
    state.user = user
  },

  queue(state, queue) {
    state.queue = queue
  },

  sounds(state, sounds) {
    state.sounds = sounds
  },

  addSound(state, newSound) {
    state.sounds.push(newSound)
  },

  updateSound(state, { index, newCommand }) {
    state.sounds[index].command = newCommand
  },

  deleteSound(state, soundIndex) {
    state.sounds.splice(soundIndex, 1)
  },

  soundQueue(state, queue) {
    state.soundQueue = queue
  },

  gifQueue(state, queue) {
    state.gifQueue = queue
  },

  isLoading(state, loading) {
    state.isLoading = loading
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
