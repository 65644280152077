import Service from '@/util/Service'

const ApiUrl = `${process.env.VUE_APP_URL_API}/v${process.env.VUE_APP_API_VERSION}`
const notificationService = new Service(ApiUrl, 'notification')

// initial state
const state = () => ({
  notifications: [],
})

// getters
const getters = {
  notifications: (state) => state.notifications,
}

// actions
const actions = {
  async getNotifications({ commit }) {
    const notifications = await notificationService.get()
    commit('setNotifications', notifications)
  },

  async create(_, payload) {
    await notificationService.post('', payload)
  },
}

// mutations
const mutations = {
  setNotifications(state, notifications) {
    state.notifications = notifications
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
