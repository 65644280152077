<script>
import { mapActions } from 'vuex'
export default {
  name: 'PageLogout',

  async created() {
    await this.logout()
    this.$nextTick(() => {
      this.$router.push('/')
    })
  },

  methods: {
    ...mapActions({
      logout: 'user/logout',
    }),
  },
}
</script>