<template>
  <div class="panel bg-grey-500"><slot /></div>
</template>

<style lang="scss" scoped>
.panel {
  background-color: #1e1e1e;
  border-radius: 5px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 16px;
  height: 500px;
}
</style>