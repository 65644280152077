<template>
  <div
    :class="`inline-block animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] ${colors[intent]} motion-reduce:animate-[spin_1.5s_linear_infinite]`"
    role="status"
  >
    <span
      class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
      >Loading...</span
    >
  </div>
</template>

<script setup>
const colors = {
  info: 'text-sky-900/[.5]',
  warning: 'text-orange-500/[.5]',
  danger: 'text-red-700/[.5]',
  success: 'text-green-700/[.5]',
}

defineProps({
  intent: {
    type: String,
    required: false,
    default: 'info',
    validator: (val) => ['info', 'warning', 'danger', 'success'].includes(val),
  },
})
</script>
