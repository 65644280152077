import { createRouter, createWebHistory } from 'vue-router';
import routeHandler from './routeHandler';
import { routes as defaultRoutes } from './routes';

const routes = routeHandler(defaultRoutes);

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
