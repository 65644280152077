<template>
  <div class="page page--dashboard">
    <Headline>
      <span class="font-light">Hi there,</span><br /><span class="font-black"
        >{{ user.username }}.</span
      >
      👋
    </Headline>

    <div class="grid grid-cols-3 gap-6 mt-3">
      <Panel
        ><donut-chart
          v-if="mostActiveUserByEvent.sound.length"
          :data="
            mostActiveUserByEvent.sound
              .slice(0, 10)
              .map((user) => ({ value: user.total, name: user.username }))
          "
          name="Top Users (Sound)"
      /></Panel>
      <Panel
        ><donut-chart
          v-if="mostActiveUserByEvent.gif.length"
          :data="
            mostActiveUserByEvent.gif
              .slice(0, 10)
              .map((user) => ({ value: user.total, name: user.username }))
          "
          name="Top Users (Gif)"
      /></Panel>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Panel from '@/components/Panel.vue'
import DonutChart from '@/components/DonutChart.vue'
import Headline from '@/components/Headline.vue'

export default {
  name: 'PageDashboard',

  components: {
    Panel,
    DonutChart,
    Headline,
  },

  computed: {
    ...mapGetters({
      user: 'user/user',
      mostActiveUserByEvent: 'statistic/mostActiveUserByEvent',
    }),
  },

  async created() {
    await this.$store.cache.dispatch('statistic/getMostActiveUserByEvent', {
      event: 'sound',
    })
    await this.$store.cache.dispatch('statistic/getMostActiveUserByEvent', {
      event: 'gif',
    })
  },
}
</script>