<template>
  <div class="flex">
    <router-link
      v-for="number in meta.last_page"
      :key="`page-${number}`"
      class="py-1 px-2"
      :to="{
        name: $route.name,
        query: {
          page: number,
        },
      }"
      :class="getClasses(number)"
      @click="emits('pagination:changePage', number)"
    >
      {{ number }}
    </router-link>
  </div>
</template>

<script setup>
const props = defineProps({
  meta: {
    type: Object,
    required: true,
  },
})

const emits = defineEmits(['pagination:changePage'])

const getClasses = (num) => {
  const classes = [
    'text-white',
    'hover:border-b-lime-200',
    'hover:border-b-2',
    'hover:text-lime-200',
  ]
  if (num !== 1) {
    // classes.push('ml-2')
  }
  if (num === props.meta.current_page) {
    classes.push('text-lime-400 border-b-lime-400 border-b-2')
  } else {
    // classes.push('bg-white')
  }

  return classes.join(' ')
  //if (index )
}
</script>
