import store from './../store'

const handler = (routes) =>
  routes.map((route) => {
    if (!route.meta.public) {
      return {
        ...route,
        beforeEnter: async (to, from, next) => {
          store.commit('user/isLoading', true)
          let hasUser = store.getters['user/hasUser']

          if (!hasUser) {
            hasUser = await store.dispatch('user/me')
          }
          store.commit('user/isLoading', false)
          if (hasUser) {
            next()
          } else {
            next('/')
          }
        },
      }
    }

    if (route.name === 'login') {
      return {
        ...route,
        beforeEnter: async (to, from, next) => {
          store.commit('user/isLoading', true)
          let hasUser = store.getters['user/hasUser']
          if (hasUser) {
            store.commit('user/isLoading', false)
            next('/dashboard')
          } else if (to.query.code) {
            await store.dispatch('user/exchangeCode', {
              code: to.query.code,
              redirectUri: process.env.VUE_APP_TWITCH_REDIRECT_URL,
            })
            hasUser = await store.dispatch('user/me')

            store.commit('user/isLoading', false)
            if (hasUser) {
              next('/dashboard')
            } else {
              next('/')
            }
          } else {
            next('/')
          }
        },
      }
    }

    if (route.name === 'home') {
      return {
        ...route,
        beforeEnter: async (to, from, next) => {
          store.commit('user/isLoading', true)
          let hasUser = store.getters['user/hasUser']

          if (!hasUser) {
            hasUser = await store.dispatch('user/me')
          }

          store.commit('user/isLoading', false)
          if (hasUser) {
            next('/dashboard')
          } else {
            next()
          }
        },
      }
    }

    return route
  })

export default handler
