import Service from '@/util/Service'

const ApiUrl = `${process.env.VUE_APP_URL_API}/v${process.env.VUE_APP_API_VERSION}`
const commandService = new Service(ApiUrl, 'command')

// initial state
const state = () => ({
  commands: [],
  isLoading: false,
  meta: {},
})

// getters
const getters = {
  commands: (state) => state.commands,
  isLoading: (state) => state.isLoading,
  meta: (state) => state.meta,
}

// actions
const actions = {
  async getCommands({ commit }, payload = { page: 1 }) {
    commit('toggleLoading')
    const { data: commands, meta } = await commandService.get(
      `?page=${payload.page}`
    )
    commit('setMeta', meta)
    commit('setCommands', commands)
    commit('toggleLoading')
  },

  async create(_, payload) {
    await commandService.post('', payload)
  },

  async update(_, payload) {
    await commandService.put(payload.id, payload)
  },

  async delete(_, id) {
    await commandService.delete(id)
  },
}

// mutations
const mutations = {
  setCommands(state, commands) {
    state.commands = commands
  },

  toggleLoading(state) {
    state.isLoading = !state.isLoading
  },

  setMeta(state, meta) {
    state.meta = meta
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
