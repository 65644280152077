import Service from '@/util/Service'

const ApiUrl = `${process.env.VUE_APP_URL_API}/v${process.env.VUE_APP_API_VERSION}`
const webhookService = new Service(ApiUrl, 'webhooks')

const getSubscriptionStatus = (status) => {
  if (status === 'enabled') {
    return 'success'
  }

  if (status === 'webhook_callback_verification_pending') {
    return 'warning'
  }

  return 'error'
}

// initial state
const state = () => ({
  subscriptions: [],
})

// getters
const getters = {
  subscriptions: (state) => state.subscriptions,
}

// actions
const actions = {
  async subscriptions({ commit }, { channel }) {
    const subscriptions = await webhookService.get(channel)
    commit(
      'setSubscriptions',
      subscriptions.map((subscription) => {
        return {
          id: subscription.id,
          status: getSubscriptionStatus(subscription.status),
          createdAt: subscription.created_at,
          type: subscription.type,
        }
      })
    )
  },
}

// mutations
const mutations = {
  setSubscriptions(state, subscriptions) {
    state.subscriptions = subscriptions
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
