import { io } from 'socket.io-client'
import { RobotRedford } from 'robotredford-events'

if (process.env.VUE_APP_WS_TOKEN) {
  const bot = new RobotRedford(process.env.VUE_APP_WS_TOKEN)
}

const socket = io(`${process.env.VUE_APP_URL_WS}`, {
  reconnectionAttempts: 50,
})

export default {
  install: (app) => {
    app.config.globalProperties.$getWebsocket = socket
    if (process.env.VUE_APP_WS_TOKEN) {
      app.config.globalProperties.$robotRedford = bot
    }
  },
}
