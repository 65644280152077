<template>
  <router-link :to="to" :class="getClasses"><slot /></router-link>
</template>

<script>
export default {
  name: 'NavigationItem',

  props: {
    to: {
      type: String,
      required: true,
    },
  },

  computed: {
    getClasses() {
      const classes = [
        'navigation__item',
        'block',
        'no-underline',
        'text-white',
        'p-4',
        'text-xs',
        'uppercase',
        'font-semibold',
        'tracking-wide',
        'hover:text-lime-400',
        'rounded-r-full',
      ]
      if (this.$route.path === this.to) {
        classes.push('bg-lime-400', 'text-zinc-900', 'hover:text-zinc-900')
      }
      return classes.join(' ')
    },
  },
}
</script>