<template>
  <div class="top-16 w-full">
    <Listbox v-model="selectedItem" @update:modelValue="onInputChange">
      <ListboxLabel v-if="label" class="text-sm text-gray-500">{{
        label
      }}</ListboxLabel>
      <div class="relative mt-1">
        <ListboxButton
          class="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
        >
          <span class="block truncate">{{ selectedItem.name }}</span>
          <span
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
          >
            <ChevronUpDownIcon
              class="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            <ListboxOption
              v-slot="{ active, selected }"
              v-for="item in items"
              :key="item.value"
              :value="item"
              as="template"
            >
              <li
                :class="[
                  active ? 'bg-lime-100 text-lime-900' : 'text-gray-900',
                  'relative cursor-default select-none py-2 pl-10 pr-4',
                ]"
              >
                <span
                  :class="[
                    selected ? 'font-medium' : 'font-normal',
                    'block truncate',
                  ]"
                  >{{ item.name }}</span
                >
                <span
                  v-if="selected"
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-lime-600"
                >
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
  label: {
    type: String,
    required: false,
    default: '',
  },

  items: {
    type: Array,
    required: true,
    default: () => [],
  },

  modelValue: {
    type: Object,
    required: false,
    default: () => ({}),
  },
})

const emits = defineEmits(['update:modelValue'])

const defaultItem = props.items.find(
  (item) => item.value === props.modelValue.value
)

const selectedItem = ref(defaultItem ? defaultItem : props.items?.[0])

const onInputChange = (payload) => {
  console.log(payload)
  emits('update:modelValue', payload)
}
</script>
