<template>
  <div
    :class="`${colors[intent].bg} ${border && colors[intent].border} ${
      colors[intent].text
    } px-4 pt-2 pb-3 shadow-md ${border && 'border-l-4'}`"
    role="alert"
  >
    <div class="flex">
      <div class="items-center justify-center flex mr-3" v-if="icon">
        <component
          :is="icons[intent]"
          :class="`h-6 w-6  ${colors[intent].text}`"
        />
      </div>
      <div v-if="title || description">
        <p class="font-bold" v-if="title">{{ title }}</p>
        <p class="text-sm" v-if="slots.description">
          <slot name="description" />
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useSlots } from 'vue'
import {
  CheckCircleIcon,
  BellAlertIcon,
  BoltIcon,
  InformationCircleIcon,
} from '@heroicons/vue/24/solid'

const colors = {
  info: {
    border: 'border-sky-700',
    bg: 'bg-sky-900/[.06]',
    text: 'text-sky-700',
  },
  warning: {
    border: 'border-orange-500',
    bg: 'bg-orange-600/[.06]',
    text: 'text-orange-600',
  },
  danger: {
    border: 'border-red-700',
    bg: 'bg-red-700/[.06]',
    text: 'text-red-700',
  },

  success: {
    border: 'border-green-700',
    bg: 'bg-green-500/[.06]',
    text: 'text-green-700',
  },
}

const icons = {
  info: InformationCircleIcon,
  warning: BellAlertIcon,
  danger: BoltIcon,
  success: CheckCircleIcon,
}

const slots = useSlots()

defineProps({
  intent: {
    type: String,
    required: false,
    default: 'info',
    validator: (val) => ['info', 'warning', 'danger', 'success'].includes(val),
  },

  title: {
    type: String,
    required: false,
  },

  icon: {
    type: Boolean,
    required: false,
    default: false,
  },

  border: {
    type: Boolean,
    required: false,
    default: false,
  },
})
</script>
