<template>
  <div class="headline leading-8 font-light">
    <slot />
  </div>
</template>

<style lang="scss">
.headline {
  font-size: 30px;
}
</style>