<template>
  <div>
    <Sidebar class="page__sidebar" v-if="showSidebar" />
    <router-view :class="getClasses" />
    <div
      class="
        loading-wrapper loading-wrapper
        fixed
        w-full
        h-full
        top-0
        flex
        items-center
        justify-center
      "
      v-if="!isOverlayRoute && isLoading"
    >
      <LoadingIndicator />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingIndicator from './components/LoadingIndicator.vue'
import Sidebar from './components/Sidebar.vue'
export default {
  name: 'App',

  components: {
    Sidebar,
    LoadingIndicator,
  },

  computed: {
    ...mapGetters({
      isLoggedIn: 'user/hasUser',
      isLoading: 'user/isLoading',
    }),

    getClasses() {
      const classes = ['py-8', 'px-4', 'main', 'relative']

      if (this.isLoggedIn && !this.isOverlayRoute) {
        classes.push('main--logged-in left-80')
      }

      if (this.isOverlayRoute) {
        classes.push('main--overlay')
      }

      return classes.join(' ')
    },

    isOverlayRoute() {
      return this.$route.meta.isOverlay
    },

    showSidebar() {
      if (this.isLoading) {
        return false
      }

      if (this.$route.meta.isOverlay) {
        return false
      }

      if (this.$route.meta.public) {
        return false
      }

      return true
    },
  },
}
</script>

<style lang="scss">
@import 'primeicons/primeicons.css';
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

#app {
  .main {
    width: calc(100% - 32px);

    &.main--logged-in {
      width: calc(100% - 300px - 32px);
    }

    &.main--overlay {
      width: 100%;
    }
  }
}
</style>
