<template>
  <div>
    <Dialog :isOpen="adding" title="Create Command" @close="onDialogClose">
      <template #description> Specify your new command. </template>

      <hr class="mt-2 mb-4" />

      <Listbox label="Type" :items="commandTypes" v-model="command.type" />

      <p
        class="text-sm text-gray-500 mt-2"
        v-if="command.type.description"
        v-html="command.type.description"
      ></p>

      <RedfordInput
        label="Command (without !)"
        v-model="command.name"
        class="mt-4"
        required
      />

      <RedfordTextarea
        label="Response"
        v-model="command.content"
        class="mt-4"
        required
      ></RedfordTextarea>

      <RedfordAlert
        class="mt-4"
        v-if="showValidationMessage"
        title="Missing required fields"
        intent="warning"
        icon
        border
      >
        <template #description>Please fill in all required fields.</template>
      </RedfordAlert>

      <template #footer>
        <DefaultButton
          intent="success"
          @click="onSave"
          rounded
          :isLoading="isLoading"
          >Save</DefaultButton
        >
        <DefaultButton intent="danger" class="ml-2" @click="onCancel" rounded
          >Cancel</DefaultButton
        >
      </template>
    </Dialog>
    <DefaultButton @click="onAdd" rounded>Create Command</DefaultButton>
    <Table class="mt-4" :isLoading="isLoadingCommands">
      <thead class="bg-slate-800">
        <tr>
          <TableHeadCell v-for="{ name, value } in tableCells" :key="value">
            {{ name }}
          </TableHeadCell>
          <TableHeadCell> Actions </TableHeadCell>
        </tr>
      </thead>
      <tbody>
        <tr v-for="command in commands" :key="command.id">
          <TableBodyCell v-for="{ value } in tableCells" :key="value">
            {{ command[value] }}</TableBodyCell
          >
          <TableBodyCell>
            <ContextMenu :items="getContextItems(command)" />
          </TableBodyCell>
        </tr>
      </tbody>
    </Table>

    <Pagination
      class="mt-2"
      :meta="commandsPaginationMeta"
      v-if="Object.keys(commandsPaginationMeta).length"
      @pagination:changePage="onPageChange"
    />

    <DialogConfirm
      :open="isDeleting"
      title="Confirm delete"
      v-model="deleteConfirm"
      :disabled="deleteConfirm !== deletingCommand.name"
      :label="getDeletionLabel"
      v-if="deletingCommand"
      @dialogConfirm:confirm="onConfirmDelete"
      @dialogConfirm:close="isDeleting = false"
    >
      <template #description>
        Are you sure you want to delete the command "{{ deletingCommand.name }}"
      </template>
    </DialogConfirm>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, computed, nextTick, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Dialog from '@/components/Dialog.vue'
import DefaultButton from '@/components/DefaultButton.vue'
import Listbox from '@/components/Listbox.vue'
import RedfordTextarea from '@/components/Forms/RedfordTextarea.vue'
import RedfordInput from '@/components/Forms/RedfordInput.vue'
import Table from '@/components/table/Table.vue'
import TableBodyCell from '@/components/table/TableBodyCell.vue'
import TableHeadCell from '@/components/table/TableHeadCell.vue'
import Pagination from '@/components/Pagination.vue'
import ContextMenu from '@/components/ContextMenu.vue'
import DialogConfirm from '@/components/Dialogs/DialogConfirm.vue'
import RedfordAlert from '@/components/RedfordAlert.vue'

onBeforeMount(async () => {
  await getCommands({ page: route.query?.page || 1 })
})

const route = useRoute()
const store = useStore()

const commandTypes = [
  {
    name: 'Text to speech',
    value: 'tts',
    description:
      'The bot says what you define write in the "Response" textarea',
  },
  {
    name: 'In Chat',
    value: 'chat',
  },
  {
    name: 'Shoutout',
    value: 'shoutout',
  },
  {
    name: 'Queue',
    value: 'queue',
  },
  {
    name: 'Sound',
    value: 'sound',
  },
  {
    name: 'Pick random user',
    value: 'pickRandom',
    description:
      'The bot will pick a random user from the known chatters. You can use <code>{targetUser}</code> to name the user that has been selected and <code>{commandUser}</code> to name the user that has fired the command.',
  },
]

const tableCells = [
  {
    name: 'Type',
    value: 'type',
  },
  { name: 'Name', value: 'name' },
  { name: 'Content', value: 'content' },
]

const command = reactive({
  type: commandTypes[0],
  name: '',
  content: '',
})

const rules = {
  name: {
    required,
  },
  content: {
    required,
  },
}

const v$ = useVuelidate(rules, command)

const isLoadingCommands = computed(() => store.getters['command/isLoading'])
const commands = computed(() => store.getters['command/commands'])
const commandsPaginationMeta = computed(() => store.getters['command/meta'])

const getDeletionLabel = computed(
  () => `Type "${deletingCommand?.value.name}" to confirm`
)

const createCommand = async (payload) => {
  await store.dispatch('command/create', payload)
}
const getCommands = async (payload) => {
  await store.dispatch('command/getCommands', payload)
}

const editCommand = async (payload) => {
  await store.dispatch('command/update', payload)
}
const deleteCommand = async (payload) => {
  await store.dispatch('command/delete', payload)
}

const page = ref(1)
const adding = ref(false)
const commandType = ref(commandTypes[0])
const commandContent = ref('')
const commandName = ref('')
const editingCommandId = ref(null)
const deletingCommand = ref(null)
const deleteConfirm = ref('')
const isDeleting = ref(false)
const showValidationMessage = ref(false)
const isLoading = ref(false)

const onAdd = () => {
  adding.value = !adding.value
}

const onDialogClose = () => {
  adding.value = false
}

const onPageChange = async (payload) => {
  page.value = payload
  await getCommands({ page: payload })
}

const getContextItems = (item) => {
  return [
    {
      label: 'Edit',
      action: onEdit,
      item,
    },
    {
      label: 'Delete',
      action: onDelete,
      item,
    },
  ]
}

const onSave = async () => {
  const valid = await v$.value.$validate()
  showValidationMessage.value = !valid
  if (!valid) return

  isLoading.value = true
  adding.value = false

  const params = {
    type: command.type.value,
    name: command.name,
    content: command.content,
  }

  if (editingCommandId.value) {
    await editCommand({
      id: editingCommandId.value,
      ...params,
    })
  } else {
    await createCommand(params)
  }
  await getCommands({ page: page.value })
  isLoading.value = false
  clearInputs()
}

const onEdit = (item) => {
  const type = commandTypes.find((command) => command.value == item.type)
  editingCommandId.value = item.id
  command.type = type
  command.content = item.content
  command.name = item.name
  nextTick(() => {
    adding.value = true
  })
}

const onDelete = (item) => {
  deletingCommand.value = item
  nextTick(() => {
    isDeleting.value = true
  })
}

const onConfirmDelete = async () => {
  await deleteCommand(deletingCommand.value.id)
  clearInputs()
  await getCommands({ page: page.value })
}

const onCancel = () => {
  clearInputs()
}

const clearInputs = () => {
  editingCommandId.value = null
  commandType.value = commandTypes[0]
  commandContent.value = ''
  commandName.value = ''
  deletingCommand.value = null
}
</script>
